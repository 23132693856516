<template>
  <UiPopup
    :model-value="modelValue"
    title="Close lead"
    description="Please indicate a reason why this lead was lost. Select from the list or add other reason."
    primary-button-text="Close lead"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @cancel="emits('cancel')"
    @confirm="submit"
  >
    <form ref="form" @submit.prevent>
      <UiInputSelect
        v-model="data.pipeline_step_id"
        :items="reasonsItems"
        name="Reason"
        placeholder="Choose a reason"
        class="mb-4"
        :error="v$.pipeline_step_id.$errors[0] ? v$.pipeline_step_id.$errors[0].$message.toString() : ''"
      />
      <UiInputTextField v-model="data.comment" name="Other" placeholder="Or add other reason" class="mb-4" />
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { InputItem, Lead, Stage } from '@/types'
import { PIPELINES, ARCHIVES_STAGES } from '~/constants'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input', 'cancel'])

type Props = {
  modelValue: boolean
  stage: Stage
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const reasonsItems = props.stage.steps
  ?.filter((s) => s.name !== 'Won' && s.name !== 'Other')
  .map((r) => ({ value: r.id, text: r.name })) as InputItem[]

const loading = ref(false)
const data = ref({
  pipeline_stage_id: undefined,
  pipeline_step_id: undefined,
  comment: '',
})

onNuxtReady(() => getArchiveStage())

const getArchiveStage = async () => {
  const archive = await useGetPipelineByCode(PIPELINES.ARCHIVE)
  data.value.pipeline_stage_id = Number(archive.stages.find((s) => s.code === ARCHIVES_STAGES.LOST)?.id)
}

const rules = computed(() => ({
  pipeline_step_id: { required: helpers.withMessage('The reason is required', required) },
  pipeline_stage_id: { required: helpers.withMessage('The stage is required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    try {
      loading.value = true
      const cleanData = omitBy(toRaw(data.value), (f) => f === '' || f === undefined)
      const payload = {
        pipeline_step_id: cleanData.pipeline_step_id,
        ...cleanData,
      } as Partial<Lead>

      const response = await useArchiveLead(props.lead.id, payload)

      emits('input', response.data)
      emits('update:modelValue', false)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
